// The code references from
// https://github.com/statnett/vue-plotly#master@3f05b63

import { createEventHook, useResizeObserver } from '@vueuse/core';
import { debounce } from 'lodash';
import type { Config, Layout, PlotData } from 'plotly.js';
import Plotly from 'plotly.js';
import type { Ref } from 'vue';
import { computed, ref, watch } from 'vue';

import type { Tail } from '#/types/core';

type UsePlotlyProps<T extends PlotData> = {
  data: Ref<Partial<T>[]>;
  layout: Ref<Partial<Layout>>;
  options: Ref<Partial<Config>>;
  autoResize?: Ref<boolean>;
  watchShallow?: Ref<boolean>;
};

const events = [
  'click',
  'hover',
  'unhover',
  'selecting',
  'selected',
  'restyle',
  'relayout',
  'autosize',
  'deselect',
  'doubleclick',
  'redraw',
  'animated',
  'afterplot',
] as const;

type PlotlyEvents = (typeof events)[number];

export function usePlotly<T extends PlotData>(
  container: Ref<any | null>,
  props: UsePlotlyProps<T>,
) {
  const { data, layout, options, autoResize, watchShallow } = props;
  let listeners: {
    fullName: string;
    handler: (...args: any) => void;
  }[] = [];
  const datarevision = ref(1);
  const eventHook = createEventHook<{
    event: PlotlyEvents;
    args: any[];
  }>();

  let resizeObserver: ReturnType<typeof useResizeObserver> | null = null;

  const internalLayout = computed(() => ({
    ...layout.value,
    autosize: autoResize?.value || undefined,
    datarevision: datarevision.value,
  }));

  // see https://plotly.com/javascript/plotlyjs-function-reference
  const methods = {
    restyle: (...params: Tail<Parameters<typeof Plotly.restyle>>) =>
      Plotly.restyle(container.value, ...params),
    update: (...params: Tail<Parameters<typeof Plotly.update>>) =>
      Plotly.update(container.value, ...params),
    addTraces: (...params: Tail<Parameters<typeof Plotly.addTraces>>) =>
      Plotly.addTraces(container.value, ...params),
    deleteTraces: (...params: Tail<Parameters<typeof Plotly.deleteTraces>>) =>
      Plotly.deleteTraces(container.value, ...params),
    moveTraces: (...params: Tail<Parameters<typeof Plotly.moveTraces>>) =>
      Plotly.moveTraces(container.value, ...params),
    extendTraces: (...params: Tail<Parameters<typeof Plotly.extendTraces>>) =>
      Plotly.extendTraces(container.value, ...params),
    prependTraces: (...params: Tail<Parameters<typeof Plotly.prependTraces>>) =>
      Plotly.prependTraces(container.value, ...params),
    purge: () => Plotly.purge(container.value),
  };

  function getPlotlyMethods() {
    return methods;
  }

  function initEvents() {
    if (resizeObserver) {
      resizeObserver.stop();
    }
    if (autoResize?.value) {
      resizeObserver = useResizeObserver(
        container,
        debounce(() => {
          if (container.value) {
            datarevision.value++;
            react();
          }
        }, 200),
      );
    }
    listeners = events.map((eventName) => {
      return {
        fullName: `plotly_${eventName}`,
        handler: (...args: any[]) => {
          // emit(eventName as any, ...args);
          eventHook.trigger({
            event: eventName,
            args,
          });
        },
      };
    });

    listeners.forEach((obj) => {
      container.value?.on(obj.fullName, obj.handler);
    });
  }

  function getOptions() {
    const el = container.value;
    let opts = options.value;
    // if width/height is not specified for toImageButton, default to el.clientWidth/clientHeight
    if (!opts) opts = {};
    if (!opts.toImageButtonOptions) opts.toImageButtonOptions = {};
    if (!opts.toImageButtonOptions.width)
      opts.toImageButtonOptions.width = el.clientWidth;
    if (!opts.toImageButtonOptions.height)
      opts.toImageButtonOptions.height = el.clientHeight;
    return opts;
  }

  function newPlot() {
    return Plotly.newPlot(
      container.value,
      data.value,
      internalLayout.value,
      getOptions(),
    );
  }
  function react() {
    return Plotly.react(
      container.value,
      data.value,
      internalLayout.value,
      getOptions(),
    );
  }

  function relayout() {
    if (!container.value) {
      console.debug('no container found. skip relayout');
      return;
    }
    return Plotly.relayout(container.value, internalLayout.value);
  }

  function hover(
    targets: { curveNumber: number; pointNumber: number }[],
    data?: string[],
  ) {
    if (document.contains(container.value)) {
      // eslint-disable-next-line @typescript-eslint/ban-ts-comment
      // @ts-ignore Plotly.Fx is available https://plotly.com/javascript/hover-events/#triggering-hover-events
      Plotly.Fx.hover(container.value, targets, data);
    }
  }

  function mount() {
    react();
    initEvents();
  }

  function beforeUnmount() {
    if (resizeObserver) {
      resizeObserver.stop();
    }
    listeners.forEach((obj) =>
      container.value.removeAllListeners(obj.fullName),
    );
    if (container.value) {
      Plotly.purge(container.value);
    }
  }

  watch(
    data,
    () => {
      datarevision.value++;
      react();
    },
    { deep: !watchShallow?.value },
  );

  watch(
    layout,
    () => {
      relayout();
    },
    { deep: !watchShallow?.value },
  );

  watch(
    options,
    () => {
      react();
    },
    { deep: !watchShallow?.value },
  );

  return {
    initEvents,
    relayout,
    plotlyMethods: getPlotlyMethods(),
    hover,
    getOptions,
    newPlot,
    react,
    onEvent: eventHook.on,
    mount,
    beforeUnmount,
  };
}
