import ApiBase from '@/core/services/ApiBase';
import type { components } from '@/types';

import type { ContractInput, ContractTypeInput } from '../pages/types';

export default class SettlementService extends ApiBase {
  // TODO: technical debt, BE to implement the config
  // async getSettlementConfig() {
  //   return await this.$http.get<
  //     components['schemas']['DetailResponse_ConfigDTO_']
  //   >(`v1.0/settlement-tracker/${'lng-basis'}/config`);
  // }

  async getSettlementData(
    contractId: ContractInput,
    settlementType: ContractTypeInput,
  ) {
    const tickerSymbol = `${contractId}-${settlementType}`;
    return await this.$http.get<
      components['schemas']['ListResponse_SettlementTrackerDTO_']
    >(`v1.0/gas/time-series/${tickerSymbol}`);
  }
}
