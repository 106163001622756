<script setup lang="ts">
import { clone, flatten, isNil, isNumber, last } from 'lodash';
import type { Layout } from 'plotly.js';
import { computed, ref } from 'vue';

import ChartUtils, { PriceChartConfig } from '@/core/misc/ChartUtils';
import { getAverage } from '@/core/misc/PriceUtilsV2';
import { getTraceColors } from '@/products/gas/config';
import { getFormattedPrice } from '@/products/gas/utils';
import SparkPlotlyChart from '@/shared/components/SparkPlotlyChart.vue';
import { UnitPrecision } from '@/shared/misc/PriceConstantsV2';
import type { components, SparkPlotData, UnitEnum } from '@/types';
import type { Nullable } from '#/types/core';
import { formatDate } from '#/utils/date';
import { parseNumber } from '#/utils/price';

import type { Criteria } from '../../types';

interface Props {
  criteria: Criteria;
  settlementTypeName?: string;
  settlementData?: components['schemas']['SettlementTrackerDTO'];
  maxAllowedLoss?: number;
  maxAllowedGain?: number;
  isFrontMonth: boolean;
  priceTypeNames: Record<string, string>[];
}

const props = withDefaults(defineProps<Props>(), {
  settlementTypeName: undefined,
  settlementData: undefined,
  maxAllowedLoss: undefined,
  maxAllowedGain: undefined,
  isFrontMonth: false,
  priceTypeNames: () => [],
});

const { getTrace } = ChartUtils('gas-leba-platform');
const chartConfig = PriceChartConfig.plotlyConfig;
const formulaPrices = ref<Nullable<Record<string, string>>>(null);
const chartData = computed(() => {
  const traces: Partial<SparkPlotData>[] = [];
  [
    sparkDesTrace.value,
    monthToDateTrace.value,
    sparkTrace.value,
    scenarioMaxAvgTrace.value,
    scenarioMinAvgTrace.value,
    scenarioUnchangedAvgTrace.value,
  ]?.forEach((v) => {
    if (v) {
      traces.push(v);
    }
  });

  return traces;
});

const releaseDates = computed(() => {
  return props.settlementData?.dataPoints?.map(
    (dataPoint) => dataPoint.releaseDate,
  );
});

const sparkDesTrace = computed(() => {
  if (!props.criteria.selectedUnit) {
    return;
  }

  const colorPalette = getTraceColors('spark-assessment');

  const trace = getTrace({
    name: getPriceTypeName('spark-assessment'),
    x: releaseDates.value,
    y: getPrices('spark-assessment'),
    colorPalette,
    unit: props.criteria.selectedUnit,
    priceType: 'spark-mtd',
  });

  // overrides
  Object.assign(trace, {
    mode: 'markers',
    marker: {
      symbol: 'diamond',
      size: PriceChartConfig.markerSize['spark-assessment'],
      color: colorPalette?.[0],
    },
  });

  return trace;
});

const monthToDateTrace = computed(() => {
  if (!props.criteria.selectedUnit) {
    return;
  }

  const colorPalette = getTraceColors('spark-mtd');

  const trace = getTrace({
    name: getPriceTypeName('spark-mtd'),
    x: releaseDates.value?.slice(0, props.settlementData?.assessmentsCompleted),
    y: getPrices('spark-mtd'),
    colorPalette,
    unit: props.criteria.selectedUnit,
    priceType: 'spark-assessment',
  });

  return trace;
});

const sparkTrace = computed(() => {
  if (!props.criteria.selectedUnit) {
    return;
  }

  const colorPalette = getTraceColors('spark-assessment');
  const prices = getPrices('spark');
  const name = getPriceTypeName('spark-assessment');

  const trace = getTrace({
    name,
    x: releaseDates.value?.slice(0, props.settlementData?.assessmentsCompleted),
    y: prices,
    colorPalette,
    unit: props.criteria.selectedUnit,
    priceType: 'spark',
  });

  // overrides
  Object.assign(trace, {
    mode: 'none',
    showlegend: false,
    hovertemplate: '',
  });

  return trace;
});

const scenarioMaxAvgTrace = computed(() => {
  if (
    !props.isFrontMonth ||
    !props.criteria.selectedUnit ||
    !props.settlementData
  ) {
    return;
  }
  const sparkDesPrices = getPrices('spark-assessment');
  const desPriceTypeName = getPriceTypeName('spark-assessment');

  const maxAvgY = getSettlementScenarioAvg(
    sparkDesPrices,
    parseNumber(props.criteria.maxGainValue),
    props.criteria.selectedUnit,
  )?.slice(props.settlementData.assessmentsCompleted - 1); // slice so it starts after assessed trace

  const colorPalette = getTraceColors('spark-mtd');

  const x = releaseDates.value?.slice(
    props.settlementData.assessmentsCompleted - 1,
  );

  const maxAvgTraceName = desPriceTypeName + ' Max Avg';
  const maxAvgTrace = getTrace({
    name: maxAvgTraceName,
    x,
    y: maxAvgY,
    colorPalette,
    unit: props.criteria.selectedUnit,
    priceType: 'spark-mtd',
    referencePriceType: true,
  });
  Object.assign(maxAvgTrace, {
    hoverinfo: 'text',
    hovertemplate: '',
    hovertext: maxAvgY.forEach((v, i) => {
      if (i !== 0) {
        return `<b>${maxAvgTraceName}</b>: ${getFormattedPrice(v, {
          unit: props.criteria.selectedUnit,
        })}`;
      }
    }),
  });
  return maxAvgTrace;
});
const scenarioMinAvgTrace = computed(() => {
  if (
    !props.isFrontMonth ||
    !props.criteria.selectedUnit ||
    !props.settlementData
  ) {
    return;
  }
  const sparkDesPrices = getPrices('spark-assessment');
  const desPriceTypeName = getPriceTypeName('spark-assessment');
  const x = releaseDates.value?.slice(
    props.settlementData.assessmentsCompleted - 1,
  );
  const minAvgY = getSettlementScenarioAvg(
    sparkDesPrices,
    -1 * parseNumber(props.criteria.maxLossValue),
    props.criteria.selectedUnit,
  )?.slice(props.settlementData.assessmentsCompleted - 1); // slice so it starts after assessed trace

  const minAvgTraceName = desPriceTypeName + ' Min Avg';
  const minAvgTrace = getTrace({
    name: minAvgTraceName,
    x,
    y: minAvgY,
    colorPalette: ['#DB2E20', '#FFFFFF'],
    unit: props.criteria.selectedUnit,
    priceType: 'spark-mtd',
    referencePriceType: true,
  });
  Object.assign(minAvgTrace, {
    hoverinfo: 'text',
    hovertemplate: '',
    hovertext: minAvgY.forEach((v, i) => {
      if (i !== 0) {
        return `<b>${minAvgTraceName}</b>: ${getFormattedPrice(v, {
          unit: props.criteria.selectedUnit,
        })}`;
      }
    }),
  });
  return minAvgTrace;
});

const scenarioUnchangedAvgTrace = computed(() => {
  if (
    !props.isFrontMonth ||
    !props.criteria.selectedUnit ||
    !props.settlementData
  ) {
    return;
  }
  const sparkDesPrices = getPrices('spark-assessment');
  const desPriceTypeName = getPriceTypeName('spark-assessment');
  const x = releaseDates.value?.slice(
    props.settlementData?.assessmentsCompleted - 1,
  );
  const unchangedAvgY = getSettlementScenarioAvg(
    sparkDesPrices,
    0,
    props.criteria.selectedUnit,
  ).slice(props.settlementData.assessmentsCompleted - 1); // slice so it starts after assessed trace

  const unchangedAvgTraceName = desPriceTypeName + ' Unchanged Avg';
  const unchangedAvgTrace = getTrace({
    name: unchangedAvgTraceName,
    x,
    y: unchangedAvgY,
    colorPalette: ['lightgray', '#2a3f5f'],
    unit: props.criteria.selectedUnit,
    priceType: 'spark-mtd',
    referencePriceType: true,
  });
  Object.assign(unchangedAvgTrace, {
    hoverinfo: 'text',
    hovertemplate: '',
    hovertext: unchangedAvgY.forEach((v, i) => {
      if (i !== 0) {
        return `<b>${unchangedAvgTraceName}</b>: ${getFormattedPrice(v, {
          unit: props.criteria.selectedUnit,
        })}`;
      }
    }),
  });
  return unchangedAvgTrace;
});

const yAxisRange = computed(() => {
  const prices =
    flatten(
      chartData.value
        ?.filter((v) => v?.priceType !== 'spark')
        ?.map((v) => v?.y) as (number | undefined)[][],
    )
      ?.map((v) => parseNumber(v))
      ?.filter((v) => !isNaN(v)) || [];

  const min = Math.min(...prices);
  const max = Math.max(...prices);
  return [min > 0 ? min * 0.9 : min * 1.1, max > 0 ? max * 1.1 : max * 0.9];
});

const plotlyLayout = computed(() => {
  return {
    margin: { l: 40, r: 40, b: 60, t: 20, pad: 4 },
    xaxis: {
      type: 'category',
      zeroline: false,
      autorange: true,
      tickvals: props.settlementData?.dataPoints?.map(
        (dataPoint) => dataPoint?.releaseDate,
      ),
      ticktext: props.settlementData?.dataPoints?.map((dataPoint) =>
        formatDate(dataPoint?.releaseDate, 'DD MMM'),
      ),
      hoverformat: '%d %b, %y',
    },
    yaxis: {
      zeroline: false,
      type: 'linear',
      range: yAxisRange.value,
    },
    legend: { orientation: 'h' },
    dragmode: false,
    hovermode: 'x',
  } as Partial<Layout>;
});

function getPrices(priceType: string) {
  return flatten(
    props.settlementData?.dataPoints?.map((dataPoint) => {
      return dataPoint.derivedPrices
        ?.find((derivedPrice) => {
          return derivedPrice?.type === priceType;
        })
        ?.values?.find((value) => {
          return value?.unit === props.criteria.selectedUnit;
        })?.value;
    }),
  )?.map((v) => parseNumber(v));
}

function getPriceTypeName(
  priceType: components['schemas']['GasTimeSeriesDerivedPriceTypeEnum'],
) {
  return (
    props.priceTypeNames?.find((priceTypeName) => {
      return priceTypeName?.id === priceType;
    })?.name || ''
  );
}

function onHover(data: any) {
  const sparkDes = getTraceValue('spark-assessment');
  const spark = getTraceValue('spark-assessment');

  if (sparkDes && spark) {
    formulaPrices.value = {
      sparkDes,
      spark,
    };
  } else {
    formulaPrices.value = null;
  }

  function getTraceValue(
    priceType: components['schemas']['GasTimeSeriesDerivedPriceTypeEnum'],
  ) {
    const trace = data?.points?.find(
      (point: any) => point?.data?.priceType === priceType,
    );
    if (trace) {
      const y = getFormattedPrice(trace.y, {
        unit: props.criteria.selectedUnit,
      });

      return y;
    }
  }
}

function onUnhover() {
  formulaPrices.value = null;
}

function getSettlementScenario(prices: Nullable<number>[], gain: number) {
  const pricesWithoutNull = clone(prices).filter(
    (price) => !(isNil(price) || isNaN(price)),
  );
  const scenario = prices.reduce((result, price, index) => {
    const pricesUntilIndex = pricesWithoutNull.slice(0, index + 1) as number[];
    const lastVal = last(pricesUntilIndex);
    if (!(isNil(price) || isNaN(price))) {
      result.push(price);
    } else if (isNumber(lastVal)) {
      pricesWithoutNull.push(lastVal + gain);
      result.push(lastVal + gain);
    }
    return result;
  }, [] as number[]);

  return scenario;
}

function getSettlementScenarioAvg(
  prices: Nullable<number>[],
  variation: number,
  unit: UnitEnum,
) {
  const scenario = getSettlementScenario(prices, variation);

  const result: (number | null)[] = [];
  scenario.forEach((price: number, index: number) => {
    const pricesUntilIndex = scenario.slice(0, index + 1);
    const avg = getAverage(
      pricesUntilIndex,
      unit,
      UnitPrecision['gas-leba-platform']?.[unit],
    );
    result.push(avg ?? null);
  });

  return result;
}
</script>
<template>
  <div class="relative mt-4 min-h-[500px]">
    <SparkPlotlyChart
      ref="settlementChart"
      :data="chartData"
      :layout="plotlyLayout"
      :options="chartConfig"
      @hover="onHover"
      @unhover="onUnhover"
    />
  </div>
</template>
