<script
  setup
  lang="ts"
  generic="T extends string | number = string, TMeta = unknown"
>
import { RadioGroupItem, RadioGroupRoot } from 'radix-vue';
import { computed, ref } from 'vue';

import type { SelectableItem, SparkProductColor } from '#/types/core';

import SparkRadioGroupItem from './SparkRadioGroupItem.vue';
import type { SparkRadioGroupItemSlotProps } from './types';

const props = withDefaults(
  defineProps<{
    options: SelectableItem<T, TMeta>[];
    modelValue?: T;
    color?: SparkProductColor;
    size?: 'sm' | 'md' | 'lg';
  }>(),
  {
    modelValue: undefined,
    color: 'green',
    size: 'sm',
    layout: 'normal',
  },
);
const emit = defineEmits<{
  'update:modelValue': [value: T | undefined];
  optionClicked: [value: T];
}>();

defineSlots<{
  'radio-group-item'?: {
    props: SparkRadioGroupItemSlotProps<T, TMeta>;
  };
}>();

const _options = computed(() => {
  return props.options.map((option) => ({
    ...option,
    stringValue: option.value.toString(),
  }));
});

const selectedValue = computed<string | undefined>({
  get: () => {
    return _options.value.find((option) => option.value === props.modelValue)
      ?.stringValue;
  },
  set: (value: string | undefined) => {
    const emitValue = _options.value.find(
      (option) => option.stringValue === value,
    )?.value;
    emit('update:modelValue', emitValue);
  },
});

const comboBoxOpen = ref(false);

const selectedOption = computed(() => {
  return _options.value.find((option) => option.value === selectedValue.value);
});

const PRODUCT_COLORS: Record<
  SparkProductColor,
  {
    onTextColor: string;
    onBgColor: string;
    offTextColor: string;
    offBgColor: string;
    borderColor: string;
    hoverBorderColor: string;
  }
> = {
  green: {
    onTextColor: 'var(--tw-white)',
    onBgColor: 'var(--tw-green-500)',
    offTextColor: 'var(--tw-black)',
    offBgColor: 'var(--tw-white)',
    borderColor: '#d9d9d9',
    hoverBorderColor: 'var(--tw-green-500)',
  },
  basis: {
    onTextColor: 'var(--tw-white)',
    onBgColor: 'var(--tw-basis-500)',
    offTextColor: 'var(--tw-black)',
    offBgColor: 'var(--tw-white)',
    borderColor: '#d9d9d9',
    hoverBorderColor: 'var(--tw-basis-500)',
  },
  access: {
    onTextColor: 'var(--tw-gray-900)',
    onBgColor: 'var(--tw-access-400)',
    offTextColor: 'var(--tw-black)',
    offBgColor: 'var(--tw-white)',
    borderColor: '#d9d9d9',
    hoverBorderColor: 'var(--tw-access-400)',
  },
  gas: {
    onTextColor: 'var(--tw-white)',
    onBgColor: 'var(--tw-gas-500)',
    offTextColor: 'var(--tw-black)',
    offBgColor: 'var(--tw-white)',
    borderColor: '#d9d9d9',
    hoverBorderColor: 'var(--tw-gas-500)',
  },
  intraday: {
    onTextColor: 'var(--tw-white)',
    onBgColor: 'var(--tw-intraday-800)',
    offTextColor: 'var(--tw-gray-600)',
    offBgColor: 'var(--tw-intraday-800)',
    borderColor: 'var(--tw-intraday-800)',
    hoverBorderColor: 'var(--tw-intraday-800)',
  },
} as const;

const productColor = computed(() => {
  return PRODUCT_COLORS[props.color];
});

function setSelectedValue(value: T | undefined) {
  selectedValue.value = value as any;
  comboBoxOpen.value = false;
}

defineExpose({
  setSelectedValue,
});
</script>

<template>
  <RadioGroupRoot
    v-model="selectedValue"
    class="group flex w-fit overflow-clip rounded-md border border-[--radio-group-item-border-color] font-medium transition-colors hover:border-[--radio-group-item-hover-border-color] data-[size=lg]:h-10 data-[size=md]:h-8 data-[size=sm]:h-6 data-[size='lg']:rounded-lg"
    :data-size="size"
    :style="{
      '--radio-group-item-border-color': productColor.borderColor,
      '--radio-group-item-hover-border-color': productColor.hoverBorderColor,
      '--radio-group-item-on-text-color': productColor.onTextColor,
      '--radio-group-item-on-bg-color': productColor.onBgColor,
      '--radio-group-item-off-bg-color': productColor.offBgColor,
      '--radio-group-item-off-text-color': productColor.offTextColor,
    }"
  >
    <RadioGroupItem
      v-for="(option, index) in _options"
      :key="option.stringValue"
      :value="option.stringValue"
      :data-size="size"
      :disabled="option.disabled"
      as-child
    >
      <slot
        name="radio-group-item"
        :option="option"
        :is-selected="option === selectedOption"
      >
        <SparkRadioGroupItem
          :disabled="option.disabled"
          @click="$emit('optionClicked', option.value)"
        >
          {{ option.name }}
        </SparkRadioGroupItem>
      </slot>
      <div
        v-if="index !== _options.length - 1"
        class="min-h-full border-l border-solid border-[--radio-group-item-border-color] group-hover:border-[--radio-group-item-hover-border-color]"
      ></div>
    </RadioGroupItem>
  </RadioGroupRoot>
</template>
